import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Badge, Cell } from "zarm";
import "zarm/dist/zarm.css";

import NavHeader from "../../../components/NavHeader/NavHeader";

export default function Dianpuguanli() {
  const history = useHistory();

  return (
    <>
      <NavHeader title={"Mall"} />
      <div style={{ marginTop: "60px" }}>
        <Cell
          hasArrow
          title="Champagne-type non-alcoholic grape juice"
          onClick={() => history.push("/PuTaoJiu")}
        />
        <Cell
          hasArrow
          title="Hyaluronic Acid Water Brightener Skin Care Essence"
          onClick={() => history.push("/ShuiGuangZheng")}
        />
        <Cell
          hasArrow
          title="The world's first comb"
          onClick={() => history.push("/Shuzi")}
        />
      </div>
    </>
  );
}
