// Auth - login
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const OAUTH = "OAUTH";

// UserProfile
export const USER = "USER";

// UserAreaRecords
export const USERAREARECORDS = "USERAREARECORDS";

// 选择底部tab
export const SELECT_TAB = "SELECT_TAB";

// 首页-选择nav
export const SET_NAV = "SET_NAV";

// 应用-是否显示CTA
export const HIDE_DOWNLOAD_APP_BANNER = "HIDE_DOWNLOAD_APP_BANNER";

// 搜索-添加/删除搜索历史
export const ADD_SEARCH_HISTORY = "ADD_SEARCH_HISTORY";
export const REMOVE_ALL_SEARCH_HISTORY = "REMOVE_ALL_SEARCH_HISTORY";

// 分类-选择类目
export const SET_CATE = "SET_CATE";


// 购物车 - 加入购物车
export const ADD_GOODS_TO_CART = "ADD_GOODS_TO_CART";
export const REMOVE_GOODS_TO_CART = "REMOVE_GOODS_TO_CART";
