import React, { useState, useRef, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import FuncHeader from "components/FuncHeader/FuncHeader";
import {
  Input,
  Cell,
  Button,
  Radio,
  FilePicker,
  Icon,
  Toast,
  Badge,
  Picker,
} from "zarm";
import "zarm/dist/zarm.css";
import { Request } from "../../../utils/request";
import {
  BASE_URL,
  CONTENT_TYPE,
  REQUEST_METHOD,
} from "../../../utils/request/config";

const MAX_FILES_COUNT = 1;

const PAY_TYPE = [
  { value: "1", label: "WeChat" },
  { value: "2", label: "Alipay" },
  { value: "3", label: "cash" },
  { value: "4", label: "Overseas Bank" },
  { value: "5", label: "Bank of China" },
];
const initState = {
  single: {
    visible: false,
    value: "",
    dataSource: PAY_TYPE,
  },
  specDOM: {
    visible: false,
    value: "",
    dataSource: PAY_TYPE,
  },
};
const reducer = (state, action) => {
  const { type, key, value } = action;

  switch (type) {
    case "visible":
      return {
        ...state,
        [key]: {
          ...state[key],
          visible: !state[key].visible,
        },
      };
    case "value":
      return {
        ...state,
        [key]: {
          ...state[key],
          value,
        },
      };
  }
};
const renderAccount = (type) => {
  switch (type) {
    case "WeChat":
      return (
        <Cell title="WeChat Wallet">
          <Input readOnly type="text" rows={3} value="WeChat Wallet" />
        </Cell>
      );
    case "Alipay":
      return (
        <Cell title="Alipay">
          <Input readOnly type="text" rows={3} value="Alipay" />
        </Cell>
      );
    case "cash":
      return (
        <Cell title="cash">
          <Input
            readOnly
            type="text"
            rows={3}
            value="Shop 7, 94 Beamish St, Campsie NSW 2194 Australia"
          />
        </Cell>
      );
    case "Bank of China":
      return (
        <Cell title="Bank of China">
          <Input
            readOnly
            type="text"
            rows={3}
            value="Bank: Industrial and Commercial Bank of China Account Name: Zhang San Card Number: 1234567890"
          />
        </Cell>
      );
    case "Overseas Bank":
      return (
        <Cell title="Overseas Bank">
          <Input
            readOnly
            type="text"
            rows={3}
            value="Bank: Westpac
                      Account name: CVA Dreams Pty Ltd
                      BSB: 032069
                      A/C: 641427"
          />
        </Cell>
      );
  }
};

export default function Recharge() {
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userEmail = useSelector((state) => state.user?.data?.email);
  let userMobile = useSelector((state) => state.user?.data?.phoneNumber);
  let walletData = useSelector((state) => state.user?.data?.wallet);
  const history = useHistory();
  const [coinType, setCoinType] = useState("cva");
  const [payPassword, setPayPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [files, setFiles] = useState([]);
  const [transactionMethod, setTransactionMethod] = useState("WeChat");

  const myRef = useRef();
  const [state, dispatch] = useReducer(reducer, initState);
  const setVisible = (key) => {
    dispatch({ type: "visible", key });
  };
  const setValue = (key, value) => {
    dispatch({ type: "value", key, value });
  };

  const onSelect = (selFiles) => {
    const newFiles = files.concat(selFiles);
    if (newFiles.length > MAX_FILES_COUNT) {
      Toast.show("You can only select 1 picture at most");
      return;
    }
    setFiles(newFiles);
  };

  const remove = (index) => {
    const newFiles = [].concat(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
    Toast.show("successfully deleted");
  };

  const imgRender = () => {
    return files.map((item, index) => {
      return (
        <Badge
          key={+index}
          className="file-picker-item"
          shape="circle"
          text={
            <span className="file-picker-closebtn">
              <Icon type="wrong" />
            </span>
          }
          onClick={() => remove(index)}
          style={{ padding: "10px" }}
        >
          <div className="file-picker-item-img">
            <a href={item.thumbnail} target="_blank" rel="noopener noreferrer">
              <img
                src={item.thumbnail}
                alt=""
                style={{
                  height: "60px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              />
            </a>
          </div>
        </Badge>
      );
    });
  };

  const sucessConfirm = (result) => {
    if (result.status == 401) {
      window.confirm("The payment password is wrong.");
    }
    if (result.status == "ERROR") {
      window.confirm("Information input error.");
    }
    if (result.status == "Success") {
      if (window.confirm("The operation is successful.")) {
        history.push("/user");
      }
    }
  };

  const handleClick = async () => {
    if (files[0]?.file) {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var formdata = new FormData();
        formdata.append("TransactionCoinType", coinType);
        formdata.append("Amount", amount);
        formdata.append("TransactionType", "RECHARGE");
        formdata.append("PayPassword", payPassword);
        formdata.append("AttachedFiles", files[0]?.file);
        formdata.append("TransactionMethod", transactionMethod);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        fetch(BASE_URL + "/Transaction", requestOptions)
          .then((response) => response.json())
          .then((result) => sucessConfirm(result))
          .catch((error) => window.confirm("unknown error"));
      } catch (error) {
        window.confirm("Unknown error");
      }
    } else {
      window.confirm("Please upload credentials.");
    }
  };
  const debounceHandleClick = _.throttle(handleClick, 8000, {
    leading: true,
    trailing: false,
  });

  const coinTypeSwitch = (type) => {
    switch (type) {
      case "cva":
        return `$ ${walletData?.cva}`;
      // case "cvt":
      // return `${walletData?.cvt}`;
      // case "abg":
      // return `${walletData?.abg}`;
    }
  };

  return (
    <>
      <FuncHeader
        title={"Offline recharge"}
        goBack={() => history.push("/user")}
      />
      <div style={{ marginTop: "60px" }}>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={coinType}
              onChange={(value) => {
                setCoinType(value);
              }}
            >
              <Radio value="cva">fill the CVA drip pool</Radio>
              {/*<Radio value="cvt">CVT delivery pass</Radio>*/}
              {/*<Radio value="abg">ABG Golden Bean</Radio>*/}
            </Radio.Group>
          }
        >
          Currency type
        </Cell>
        <Cell title="Current balance">{coinTypeSwitch(coinType)}</Cell>
        <Cell title="Recharge amount">
          <Input
            type="number"
            value={amount}
            onChange={(value) => {
              setAmount(value);
            }}
            placeholder={"Please enter the recharge amount"}
          />
        </Cell>
        <Cell
          description={
            <Button size="xs" onClick={() => setVisible("single")}>
              Please click to select
            </Button>
          }
        >
          payment method
        </Cell>
        <Picker
          visible={state.single.visible}
          value={state.single.value}
          dataSource={state.single.dataSource}
          onOk={(selected) => {
            Toast.show("You selected" + selected[0]?.label);
            setTransactionMethod(selected[0]?.label);
            setValue(
              "single",
              selected.map((item) => item.value)
            );
            setVisible("single");
          }}
          onCancel={() => setVisible("single")}
        />
        {renderAccount(transactionMethod)}
        <Cell title="Recharge Voucher">
          <div className="file-picker-wrapper" style={{ marginTop: "10px" }}>
            {imgRender()}
            {files.length < MAX_FILES_COUNT && (
              <FilePicker
                multiple
                className="file-picker-btn"
                accept="image/*"
                // onBeforeSelect={onBeforeSelect}
                onChange={onSelect}
              >
                <Icon type="add" size="lg" />
              </FilePicker>
            )}
          </div>
          {/*<Input*/}
          {/*    type="text"*/}
          {/*    value={toUserName}*/}
          {/*    onChange={(value) => {*/}
          {/*      setToUserName(value);*/}
          {/*    }}*/}
          {/*    placeholder={"请输入对方用户名"}*/}
          {/*/>*/}
        </Cell>
        {/*<Cell*/}
        {/*  description={*/}
        {/*    <Radio.Group*/}
        {/*      type="button"*/}
        {/*      value={verifyType}*/}
        {/*      onChange={(value) => {*/}
        {/*        setVerifyType(value);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Radio value="email">邮箱</Radio>*/}
        {/*      <Radio value="mobile">手机</Radio>*/}
        {/*    </Radio.Group>*/}
        {/*  }*/}
        {/*>*/}
        {/*  接收Verification code*/}
        {/*</Cell>*/}
        {/*<Cell>*/}
        {/*  <Button*/}
        {/*    block*/}
        {/*    style={{ marginTop: "20px", marginBottom: "20px" }}*/}
        {/*    onClick={() => getCode()}*/}
        {/*  >*/}
        {/*    获取Verification code*/}
        {/*  </Button>*/}
        {/*</Cell>*/}
        <Cell title="支付密码">
          <Input
            type="password"
            value={payPassword}
            onChange={(value) => {
              setPayPassword(value);
            }}
            placeholder={"请输入支付密码"}
          />
        </Cell>
        {/*<Cell title="支付密码">*/}
        {/*  <Input*/}
        {/*      type="password"*/}
        {/*      value={payPassword}*/}
        {/*      onChange={(value) => {*/}
        {/*        setPayPassword(value);*/}
        {/*      }}*/}
        {/*      placeholder={"请输入支付密码"}*/}
        {/*  />*/}
        {/*</Cell>*/}
        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => debounceHandleClick()}
          >
            Submit recharge
          </Button>
        </Cell>
      </div>
    </>
  );
}
