import { createReducer } from "./reducerCreator";
import * as types from "../actionTypes/types";

const initialState = {
  isAuthed: false,
  token: null,
  userId: null
};

const AuthReducer = createReducer(initialState, {
  [types.LOGIN]: (state = initialState, action) => {
    return {
      isAuthed: true,
      token: action.payload.token,
      userId: action.payload.userId
    };
  },
  [types.REGISTER]: (state = initialState, action) => {
    return {
      isAuthed: false,
      token: null,
      userId: null
    };
  },
  [types.LOGOUT]: (state = initialState, action) => {
    return {
      isAuthed: false,
      token: null,
      userId: null
    };
  },
});

export default AuthReducer;
