import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import FuncHeader from "components/FuncHeader/FuncHeader";
import {
  Input,
  Cell,
  Button,
  Radio,
  FilePicker,
  Icon,
  Toast,
  Badge,
} from "zarm";
import "zarm/dist/zarm.css";
import { Request } from "../../../utils/request";
import {
  BASE_URL,
  CONTENT_TYPE,
  REQUEST_METHOD,
} from "../../../utils/request/config";

const MAX_FILES_COUNT = 1;

export default function Reimbursement() {
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userEmail = useSelector((state) => state.user?.data?.email);
  let userMobile = useSelector((state) => state.user?.data?.phoneNumber);
  let walletData = useSelector((state) => state.user?.data?.wallet);
  const history = useHistory();
  const [coinType, setCoinType] = useState("cva");
  const [transType, setTransType] = useState("aud");
  const [verifyType, setVerifyType] = useState("email");
  const [payPassword, setPayPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [toVerifyCode, setVerifyCode] = useState("");
  const [files, setFiles] = useState([]);
  const [seconds, setSeconds] = useState(60);
  const [resend, setResend] = useState(false);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(60);
      setResend(false);
    }
  });

  const onSelect = (selFiles) => {
    const newFiles = files.concat(selFiles);
    if (newFiles.length > MAX_FILES_COUNT) {
      Toast.show("You can only select at most 1 picture");
      return;
    }
    setFiles(newFiles);
  };

  const remove = (index) => {
    const newFiles = [].concat(files);
    newFiles.splice(index, 1);
    setFiles(newFiles);
    Toast.show("Delete successfully");
  };

  const imgRender = () => {
    return files.map((item, index) => {
      return (
        <Badge
          key={+index}
          className="file-picker-item"
          shape="circle"
          text={
            <span className="file-picker-closebtn">
              <Icon type="wrong" />
            </span>
          }
          onClick={() => remove(index)}
          style={{ padding: "10px" }}
        >
          <div className="file-picker-item-img">
            <a href={item.thumbnail} target="_blank" rel="noopener noreferrer">
              <img
                src={item.thumbnail}
                alt=""
                style={{
                  height: "60px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              />
            </a>
          </div>
        </Badge>
      );
    });
  };

  const getCode = async () => {
    if (
      amount >= 100 &&
      files[0]?.file &&
      payPassword &&
      walletData?.cvt >= 30000
    ) {
      let request = new Request();
      let url;
      let body;
      verifyType === "email"
        ? (body = { Email: userEmail })
        : (body = { Mobile: userMobile });
      verifyType === "email"
        ? (url = "/UserProfile/email_code")
        : (url = "/UserProfile/sms_code");
      try {
        let response = await request.fetchData(url, {
          method: REQUEST_METHOD.POST,
          contentType: CONTENT_TYPE.JSON,
          token,
          body,
        });
        window.confirm("Sent successfully");
        setResend(true);
      } catch (error) {
        window.confirm("Unknown error");
      }
    } else {
      window.confirm(
        "Please enter the amount of reimbursement, not less than CVA$100, CVT not less than 30,000, and enter all the information."
      );
    }
  };
  const debounceGetCode = _.throttle(getCode, 8000, {
    leading: true,
    trailing: false,
  });

  const sucessConfirm = (result) => {
    console.log(result.status);
    if (result.status == 401) {
      window.confirm("The payment password is incorrect. ");
    }
    if (result.status == "ERROR") {
      window.confirm("Information input error.");
    }
    if (result.status == "Success") {
      if (window.confirm("The operation is successful.")) {
        history.push("/user");
      }
    }
  };

  const handleClick = async () => {
    if (toVerifyCode) {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var formdata = new FormData();
        formdata.append("TransactionCoinType", coinType);
        formdata.append("Amount", amount);
        formdata.append("PayPassword", payPassword);
        formdata.append("TransactionType", "CLAIM");
        formdata.append("VerifyCode", toVerifyCode);
        formdata.append("AttachedFiles", files[0]?.file);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        fetch(BASE_URL + "/Transaction", requestOptions)
          .then((response) => response.json())
          .then((result) => sucessConfirm(result))
          .catch((error) => window.confirm("unknown error"));
      } catch (error) {
        window.confirm("Unknown error");
      }
    } else {
      window.confirm("Please enter the verification code.");
    }
  };
  const debounceHandleClick = _.throttle(handleClick, 8000, {
    leading: true,
    trailing: false,
  });

  const coinTypeSwitch = (type) => {
    switch (type) {
      case "cva":
        return `$ ${walletData?.cva}`;
      case "cvt":
        return `${walletData?.cvt}`;
      case "abg":
        return `${walletData?.abg}`;
    }
  };

  return (
    <>
      <FuncHeader
        title={"Consumption subsidies"}
        goBack={() => history.push("/user")}
      />
      <div style={{ marginTop: "60px" }}>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={coinType}
              onChange={(value) => {
                setCoinType(value);
              }}
            >
              <Radio value="cva">
                CVT delivery pass is transferred to CVA drip pool
              </Radio>
              {/*<Radio value="cvt">CVT delivery pass</Radio>*/}
              {/*<Radio value="abg">ABG Golden Bean</Radio>*/}
            </Radio.Group>
          }
        >
          Type of reimbursement
        </Cell>
        <Cell title="CVT balance">{walletData?.cvt} CVT</Cell>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={transType}
              onChange={(value) => {
                setTransType(value);
              }}
            >
              <Radio value="aud">Australian Dollar</Radio>
              <Radio value="cny">RMB</Radio>
              {/*<Radio value="block">digital currency</Radio>*/}
            </Radio.Group>
          }
        >
          Currency type
        </Cell>
        {/*<Cell title="Current balance">{coinTypeSwitch(coinType)}</Cell>*/}
        <Cell title="Reimbursement Amount ($)">
          <Input
            type="number"
            value={amount}
            onChange={(value) => {
              setAmount(value);
            }}
            placeholder={"Please enter the reimbursement amount"}
          />
        </Cell>
        <Cell title="Document certificate">
          <div className="file-picker-wrapper" style={{ marginTop: "10px" }}>
            {imgRender()}
            {files.length < MAX_FILES_COUNT && (
              <FilePicker
                multiple
                className="file-picker-btn"
                accept="image/*"
                onChange={onSelect}
              >
                <Icon type="add" size="lg" />
              </FilePicker>
            )}
          </div>
          {/*<Input*/}
          {/*    type="text"*/}
          {/*    value={toUserName}*/}
          {/*    onChange={(value) => {*/}
          {/*      setToUserName(value);*/}
          {/*    }}*/}
          {/*    placeholder={"请输入对方用户名"}*/}
          {/*/>*/}
        </Cell>
        <Cell title="Payment password">
          <Input
            type="password"
            value={payPassword}
            onChange={(value) => {
              setPayPassword(value);
            }}
            placeholder={"Please enter the payment password"}
          />
        </Cell>
        <Cell
          description={
            <Radio.Group
              type="button"
              value={verifyType}
              onChange={(value) => {
                setVerifyType(value);
              }}
            >
              <Radio value="email">Email</Radio>
              <Radio value="mobile">Mobile</Radio>
            </Radio.Group>
          }
        >
          Receive verification code
        </Cell>
        <Cell>
          <Button
            block
            disabled={resend}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            onClick={() => debounceGetCode()}
          >
            {resend ? `Wait for ${seconds} to resend` : "Get verification code"}
          </Button>
        </Cell>
        <Cell title="Verification Code">
          <Input
            type="text"
            value={toVerifyCode}
            onChange={(value) => {
              setVerifyCode(value);
            }}
            placeholder={"Please enter the verification code"}
          />
        </Cell>

        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => debounceHandleClick()}
          >
            Submit reimbursement
          </Button>
        </Cell>
      </div>
    </>
  );
}
