import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FuncHeader from "components/FuncHeader/FuncHeader";

export default function Aboutus() {
  const history = useHistory();

  return (
    <>
      <FuncHeader title={"About us"} goBack={() => history.push("/user")} />
      <div
        style={{
          marginTop: "80px",
          justifyContent: "center",
          padding: "20px",
          fontSize: "20px",
        }}
      >
        <p>CVA realizes dream consumption value-added system</p>
        <p>
          The idea of ​​consuming to get rich is to turn money-making things
          into money-making things, and turn family expenses into family income.
          Change the money that you had to make, buy the things you had to buy,
          and make money that you couldn't make before. Change the way of
          consumption, planned delayed consumption can change life, Let one-time
          consumption, benefit for life, live for free, and realize the dream
          ecosystem!
          <br />
          The consumption growth cycle system breaks through the secrets of the
          enterprise distribution system and finds a path of evergreen
          development for global entrepreneurs. Enable consumers to “spend” and
          use CVT to pick up the goods, Continue to redeem products for free,
          which is equivalent to helping merchants continue to sell products!
          <br />
          The five-block dividend model is to use the time of consumption,
          quantification, orderly combination, cyclic rebirth or upgrade,
          Continuously release dividends and delivery tokens, allowing consumers
          to double their profits, Ensure the benign continuation of the five
          blocks. Will surely create the future for the society! Lead the new
          trend of consumer value-added! Become a new model of consumption to
          get rich! It is a historic dream project!
          <br />
        </p>
        <p>CVA Dreams Pty Ltd</p>
        <p>ABN 65 641 094 877</p>
        <p>Address: Shop 7, 94 Beamish St, Campsie NSW 2194 Australia</p>
        <p>Website: cvac.net.au</p>
        <p>Phone: +61 0481 001 663</p>
        <p>
          Please send the form and deposit/transfer receipt to cva_rego@163.com
        </p>
      </div>
    </>
  );
}
