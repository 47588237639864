import React, { useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { useCart } from "react-use-cart";
import FuncHeader from "../../components/FuncHeader/FuncHeader";
import * as QueryString from "query-string";
import axios from "axios";
import { BASE_URL } from "../../utils/request/config";
import { useSelector } from "react-redux";
import { Cell, Stepper, Button, Input, Modal } from "zarm";
import _ from "lodash";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const initState = {
  hasFooter: {
    visible: false,
  },
};
const reducer = (state, action) => {
  const { type, key } = action;

  switch (type) {
    case "visible":
      return {
        ...state,
        [key]: {
          ...state[key],
          visible: !state[key].visible,
        },
      };
    default:
  }
};

let iId = null;

export default function RptProductDetail() {
  const history = useHistory();
  let isAuthed = useSelector((state) => state.auth.isAuthed);
  const { addItem } = useCart();
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userAddress = useSelector((state) => state.user?.data?.address);
  const [product, setProduct] = useState([]);
  const [pic, setPic] = useState([]);
  const [qty, setQty] = useState(1);
  const [state, dispatch] = useReducer(reducer, initState);
  const [content, setContent] = useState("None");

  const toggle = (key) => dispatch({ type: "visible", key });

  iId = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }).itemId;

  function getProduct(id, token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(BASE_URL + "/shop/rptProduct/" + id, config)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        if (response.data.data) {
          setProduct(response.data.data);
        }
      })
      .catch((err) => {
        throw err[1];
      });
  }

  const handleClick = async () => {
    if (qty) {
      var axios = require("axios");
      var data = JSON.stringify({
        Address: userAddress,
        OrderDetail: content,
        SellerId: product?.userId,
        OrderProducts: [
          {
            ProductId: iId,
            Quantity: qty,
          },
        ],
      });

      var config = {
        method: "post",
        url: BASE_URL + "/Order/product_order",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          history.push("/dingdan");
          // console.log(JSON.stringify(response.data));
          // toggle("hasFooter");
        })
        .catch(function (error) {
          if (error.response) {
            console.log("Insufficient balance");
            if (
              error.response.data.message ==
              '{"ClassName":"System.Exception","Message":"insufficient Cvt Balance","Data":null,"InnerException":null,"HelpURL":null,"StackTraceString":" at cva_api.Controllers. OrderController.AddProductOrder(AddProductOrderRequest addProductOrderRequest) in /source/cva-api/Controllers/OrderController.cs:line 420","RemoteStackTraceString":null,"RemoteStackIndex":0,"ExceptionMethod":null,"HResult":-2146233088, "Source":"cva-api","WatsonBuckets":null}'
            ) {
              window.confirm("Insufficient balance");
            }
          }
        });
    } else {
      window.confirm("The quantity is at least 1.");
    }
  };
  const debounceHandleClick = _.throttle(handleClick, 8000, {
    leading: true,
    trailing: false,
  });

  useEffect(() => {
    if (iId) {
      getProduct(iId, token);
    }
  }, [iId]);

  useEffect(() => {
    if (product?.productImages) {
      const images = product?.productImages.split("&&");
      setPic(images.slice(0, images.length - 1));
    }
  }, [product]);

  const handleAddItem = (item) => {
    addItem(item);
    alert("Added successfully");
    history.push("/category");
  };

  return (
    <>
      <FuncHeader title={product?.name} goBack={() => history.push("/user")} />
      <div style={{ marginTop: "5rem", marginBottom: "5rem" }}>
        <div className="carousel__item__pic">
          <Carousel autoPlay>
            {pic.map((n) => (
              <div>
                <img
                  src={n}
                  alt=""
                  draggable={true}
                  style={{ width: "100%" }}
                />
              </div>
            ))}
          </Carousel>
          <br />
          <Cell
            title="currency type"
            description={
              <p style={{ color: "red", fontSize: "16px" }}>
                {product?.currency}
              </p>
            }
          />
          <Cell
            title="price"
            description={
              <p style={{ color: "red", fontSize: "16px" }}>{product?.price}</p>
            }
          />
          {/*<Cell*/}
          {/* title="Points"*/}
          {/* description={*/}
          {/* <p style={{ color: "red", fontSize: "16px" }}>*/}
          {/* {product?.price*product?.user?.rptRate}*/}
          {/* </p>*/}
          {/* }*/}
          {/*/>*/}
          <Cell
            title="Inventory"
            description={
              <p style={{ fontSize: "15px" }}>{product?.quantity}</p>
            }
          />
          <Cell
            title="details"
            description={
              <p style={{ fontSize: "15px" }}>{product?.productDescription}</p>
            }
          />
          <Cell
            title="Business"
            description={
              <p style={{ fontSize: "15px" }}>{product?.companyName}</p>
            }
          />
          <Cell
            title="category"
            description={
              <p style={{ fontSize: "15px" }}>{product?.category?.name}</p>
            }
          />
          <Cell
            title="Business address"
            description={<p style={{ fontSize: "15px" }}>{product?.address}</p>}
          />
          <Cell
            title="Business website"
            description={
              <p style={{ fontSize: "15px" }}>{product?.user?.companyLink}</p>
            }
          />
          {/*<Cell*/}
          {/* title="Purchase Quantity"*/}
          {/* description={*/}
          {/* <Stepper*/}
          {/* value={qty}*/}
          {/* onChange={setQty}*/}
          {/* onInputChange={(qty) => {*/}
          {/* setQty(qty);*/}
          {/* }}*/}
          {/* />*/}
          {/* }*/}
          {/*/>*/}
          {/*<br />*/}
          {/*<Button*/}
          {/* block*/}
          {/* theme="primary"*/}
          {/* onClick={() =>*/}
          {/* userAddress*/}
          {/*? toggle("hasFooter")*/}
          {/*: window.confirm("Please bind the delivery address.")*/}
          {/* }*/}
          {/*>*/}
          {/*  Buy*/}
          {/*</Button>*/}
          {/*<br />*/}
          {/*<Button*/}
          {/* block*/}
          {/* onClick={() => {*/}
          {/* product &&*/}
          {/* handleAddItem({*/}
          {/* username: product?.companyName,*/}
          {/* quantity: qty,*/}
          {/* userId: product.userId,*/}
          {/* categoryInfo: product.category?.name,*/}
          {/* name: product?.name,*/}
          {/* imageUrl: pic[0],*/}
          {/* id: product?.id,*/}
          {/* price: qty * product.cvtPrice,*/}
          {/* });*/}
          {/* }}*/}
          {/*>*/}
          {/*  add to the cart*/}
          {/*</Button>*/}
          <Modal
            title="Order Summary"
            visible={state.hasFooter.visible}
            footer={
              <div style={{ width: "100%" }}>
                <Button
                  block
                  theme="primary"
                  onClick={() => debounceHandleClick()}
                >
                  confirm purchase
                </Button>
                <br />
                <Button block onClick={() => toggle("hasFooter")}>
                  Cancel
                </Button>
              </div>
            }
          >
            <Cell
              title="Product"
              description={<p style={{ fontSize: "15px" }}>{product?.name}</p>}
            />
            <Cell
              title="quantity"
              description={<p style={{ fontSize: "15px" }}>{qty}</p>}
            />
            <Cell
              title="Receiving address"
              description={<p style={{ fontSize: "15px" }}>{userAddress}</p>}
            />
            <Cell title="Remarks">
              <Input
                rows={3}
                type="text"
                placeholder="Please enter"
                value={content}
                onChange={setContent}
              />
            </Cell>
          </Modal>
        </div>
      </div>
    </>
  );
}
