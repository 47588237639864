import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import FuncHeader from "components/FuncHeader/FuncHeader";
import { Input, Cell, Button, Radio } from "zarm";
import "zarm/dist/zarm.css";
import { Request } from "../../../utils/request";
import {
  BASE_URL,
  CONTENT_TYPE,
  REQUEST_METHOD,
} from "../../../utils/request/config";

export default function Transefer() {
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userIdCard = useSelector((state) => state.user?.data?.idCard);
  let userPassport = useSelector((state) => state.user?.data?.passport);
  let userEmail = useSelector((state) => state.user?.data?.email);
  let userMobile = useSelector((state) => state.user?.data?.phoneNumber);
  let walletData = useSelector((state) => state.user?.data?.wallet);
  const history = useHistory();
  const [coinType, setCoinType] = useState("cva");
  const [verifyType, setVerifyType] = useState("email");
  const [payPassword, setPayPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [toUserName, setToUserName] = useState("");
  const [toVerifyCode, setVerifyCode] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [resend, setResend] = useState(false);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(60);
      setResend(false);
    }
  });

  const getCode = async () => {
    if (amount > 0 && toUserName && payPassword) {
      let request = new Request();
      let url;
      let body;
      verifyType === "email"
        ? (body = { Email: userEmail })
        : (body = { Mobile: userMobile });
      verifyType === "email"
        ? (url = "/UserProfile/email_code")
        : (url = "/UserProfile/sms_code");
      try {
        let response = await request.fetchData(url, {
          method: REQUEST_METHOD.POST,
          contentType: CONTENT_TYPE.JSON,
          token,
          body,
        });
        window.confirm("Sent successfully");
        setResend(true);
      } catch (error) {
        window.confirm("Unknown error");
      }
    } else {
      window.confirm("Please enter all information.");
    }
  };
  const debounceGetCode = _.throttle(getCode, 8000, {
    leading: true,
    trailing: false,
  });

  const handleClick = async () => {
    if (toVerifyCode) {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        var formdata = new FormData();
        formdata.append("TransactionCoinType", coinType);
        formdata.append("Amount", amount);
        formdata.append("TransactionType", "TRANSEFER");
        formdata.append("VerifyCode", toVerifyCode);
        formdata.append("ToUserName", toUserName);
        formdata.append("PayPassword", payPassword);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        fetch(BASE_URL + "/Transaction", requestOptions)
          .then((response) => response.json())
          .then((result) => sucessConfirm(result))
          .catch((error) => window.confirm("unknown error"));
      } catch (error) {
        window.confirm("Unknown error");
      }
    } else {
      window.confirm("Please enter the verification code");
    }
  };
  const debounceHandleClick = _.throttle(handleClick, 8000, {
    leading: true,
    trailing: false,
  });
  const sucessConfirm = (result) => {
    console.log(result.status);
    if (result.status == 401) {
      window.confirm("The payment password is wrong.");
    }
    if (result.status == "ERROR") {
      window.confirm("Information input error.");
    }
    if (result.status == "SUCCESS") {
      if (window.confirm("The operation is successful.")) {
        history.push("/user");
      }
    }
  };

  const coinTypeSwitch = (type) => {
    switch (type) {
      case "cva":
        return `${walletData?.cva}`;
      case "cvt":
        return `${walletData?.cvt}`;
      case "abg":
        return `${walletData?.abg}`;
    }
  };

  return (
    <>
      <FuncHeader
        title={"Mutual transfer"}
        goBack={() => history.push("/user")}
      />
      {userPassport || userIdCard ? (
        <div style={{ marginTop: "60px" }}>
          <Cell
            description={
              <Radio.Group
                type="button"
                value={coinType}
                onChange={(value) => {
                  setCoinType(value);
                }}
              >
                <Radio value="cva">CVA drip pool</Radio>
                <Radio value="cvt">CVT delivery pass</Radio>
                <Radio value="abg">ABG Golden Bean</Radio>
              </Radio.Group>
            }
          >
            Conversion type
          </Cell>
          <Cell title="Current balance">{coinTypeSwitch(coinType)}</Cell>
          <Cell title="Number of transfers each other">
            <Input
              type="number"
              value={amount}
              onChange={(value) => {
                setAmount(value);
              }}
              placeholder={"Please enter the number of transfers"}
            />
          </Cell>
          <Cell title="other user name">
            <Input
              type="text"
              value={toUserName}
              onChange={(value) => {
                setToUserName(value);
              }}
              placeholder={"Please enter the user name of the other party"}
            />
          </Cell>
          <Cell title="Payment Password">
            <Input
              type="password"
              value={payPassword}
              onChange={(value) => {
                setPayPassword(value);
              }}
              placeholder={"Please enter the payment password"}
            />
          </Cell>
          <Cell
            description={
              <Radio.Group
                type="button"
                value={verifyType}
                onChange={(value) => {
                  setVerifyType(value);
                }}
              >
                <Radio value="email">Email</Radio>
                <Radio value="mobile">Mobile</Radio>
              </Radio.Group>
            }
          >
            Receive verification code
          </Cell>
          <Cell>
            <Button
              block
              disabled={resend}
              style={{ marginTop: "20px", marginBottom: "20px" }}
              onClick={() => debounceGetCode()}
            >
              {resend
                ? `Wait for ${seconds} to resend`
                : "Get verification code"}
            </Button>
          </Cell>
          <Cell title="Verification Code">
            <Input
              type="text"
              value={toVerifyCode}
              onChange={(value) => {
                setVerifyCode(value);
              }}
              placeholder={"Please enter the verification code"}
            />
          </Cell>

          <Cell>
            <Button
              block
              theme="primary"
              style={{ marginTop: "20px" }}
              onClick={() => debounceHandleClick()}
            >
              Submit mutual transfer
            </Button>
          </Cell>
        </div>
      ) : (
        <div style={{ marginTop: "60px" }}>
          <h3>
            Please upload your credentials to complete the real-name
            authentication.{" "}
          </h3>
        </div>
      )}
    </>
  );
}
