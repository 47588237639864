import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input, Button, Cell, Picker } from "zarm";
import "zarm/dist/zarm.css";

import FuncHeader from "components/FuncHeader/FuncHeader";
import { BASE_URL } from "../../../utils/request/config";

const SINGLE_DATA = [
  { value: "+61", label: "Australia" },
  { value: "+86", label: "Mainland China" },
  { value: "+852", label: "Hong Kong" },
  { value: "+853", label: "Macao" },
  { value: "+886", label: "Taiwan" },
  { value: "+213", label: "Algeria" },
  { value: "+33", label: "Andorra" },
  { value: "+244", label: "Angola" },
  { value: "+1", label: "Anguilla" },
  { value: "+1", label: "Antigua and Mabuda" },
  { value: "+54", label: "Argentina" },
  { value: "+247", label: "Asuncion" },
  { value: "+43", label: "Austria" },
  { value: "+1", label: "Bahamas" },
  { value: "+973", label: "Bahrain" },
  { value: "+880", label: "Bangladesh" },
  { value: "+1", label: "Barbados" },
  { value: "+32", label: "Belgium" },
  { value: "+501", label: "Belize" },
  { value: "+229", label: "Benin" },
  { value: "+1", label: "Bermuda Islands" },
  { value: "+591", label: "Bolivia" },
  { value: "+267", label: "Botswana" },
  { value: "+55", label: "Brazil" },
  { value: "+673", label: "Brunei" },
  { value: "+359", label: "Bulgaria" },
  { value: "+226", label: "Burkina Faso" },
  { value: "+95", label: "Myanmar" },
  { value: "+257", label: "Burundi" },
  { value: "+237", label: "Cameron" },
  { value: "+1", label: "Canada" },
  { value: "+34", label: "Canary Islands" },
  { value: "+1", label: "Cayman Islands" },
  { value: "+56", label: "Chile" },
  { value: "+57", label: "Colombia" },
  { value: "+242", label: "Congo" },
  { value: "+682", label: "Cock Islands" },
  { value: "+506", label: "Costa Rica" },
  { value: "+53", label: "Cuba" },
  { value: "+357", label: "Cyprus" },
  { value: "+42", label: "Czech Slovakia" },
  { value: "+45", label: "Denmark" },
  { value: "+253", label: "Djibouti" },
  { value: "+1", label: "Dominican Republic" },
  { value: "+593", label: "Ecuador" },
  { value: "+20", label: "Egypt" },
  { value: "+503", label: "El Salvador" },
  { value: "+251", label: "Ethiopia" },
  { value: "+679", label: "Fiji" },
  { value: "+358", label: "Finland" },
  { value: "+33", label: "France" },
  { value: "+594", label: "French Guiana" },
  { value: "+241", label: "Gabon" },
  { value: "+220", label: "Gambia" },
  { value: "+49", label: "Federal Republic of Germany" },
  { value: "+350", label: "Gibraltar" },
  { value: "+30", label: "Greece" },
  { value: "+1", label: "Grenada" },
  { value: "+671", label: "Guam" },
  { value: "+502", label: "Guatemala" },
  { value: "+592", label: "Guyana" },
  { value: "+509", label: "Haiti" },
  { value: "+504", label: "Honduras" },
  { value: "+36", label: "Hungary" },
  { value: "+354", label: "Iceland" },
  { value: "+91", label: "India" },
  { value: "+62", label: "Indonesia" },
  { value: "+98", label: "Iran" },
  { value: "+964", label: "Iraq" },
  { value: "+353", label: "Ireland" },
  { value: "+39", label: "Italy" },
  { value: "+225", label: "Côte d’Ivoire" },
  { value: "+1", label: "Jamaica" },
  { value: "+81", label: "Japan" },
  { value: "+962", label: "Jordan" },
  { value: "+254", label: "Kenya" },
  { value: "+965", label: "Kuwait" },
  { value: "+961", label: "Lebanon" },
  { value: "+266", label: "Lesotho" },
  { value: "+231", label: "Liberia" },
  { value: "+218", label: "Libya" },
  { value: "+41", label: "Liechtenstein" },
  { value: "+352", label: "Luxembourg" },
  { value: "+261", label: "Madagascar" },
  { value: "+265", label: "Malawi" },
  { value: "+60", label: "Malaysia" },
  { value: "+960", label: "Maldives" },
  { value: "+223", label: "Mali" },
  { value: "+356", label: "Malta" },
  { value: "+670", label: "Mariana Islands" },
  { value: "+596", label: "Martinique" },
  { value: "+230", label: "Mauritius" },
  { value: "+52", label: "Mexico" },
  { value: "+33", label: "Monaco" },
  { value: "+1", label: "Montserrat" },
  { value: "+212", label: "Morocco" },
  { value: "+258", label: "Mozambique" },
  { value: "+674", label: "Nauru" },
  { value: "+977", label: "Nepal" },
  { value: "+31", label: "Netherlands" },
  { value: "+599", label: "Netherlands Antilles" },
  { value: "+587", label: "New Caledonia Islands" },
  { value: "+64", label: "New Zealand" },
  { value: "+505", label: "Nicaragua" },
  { value: "+227", label: "Niger" },
  { value: "+234", label: "Nigeria" },
  { value: "+47", label: "Norway" },
  { value: "+968", label: "Oman" },
  { value: "+92", label: "Pakistan" },
  { value: "+507", label: "Panama" },
  { value: "+675", label: "Papua New Guinea" },
  { value: "+595", label: "Paraguay" },
  { value: "+51", label: "Peru" },
  { value: "+63", label: "Philippines" },
  { value: "+48", label: "Poland" },
  { value: "+689", label: "Polynesia" },
  { value: "+351", label: "Portugal" },
  { value: "+1", label: "Puerto Rico" },
  { value: "+974", label: "Qatar" },
  { value: "+262", label: "Reunion" },
  { value: "+40", label: "Romania" },
  { value: "+684", label: "Eastern Samoa (America)" },
  { value: "+685", label: "Western Samoa" },
  { value: "+39", label: "San Marino" },
  { value: "+239", label: "Sao Tome and Principe" },
  { value: "+966", label: "Saudi Arabia" },
  { value: "+221", label: "Senegal" },
  { value: "+248", label: "Seychelles" },
  { value: "+232", label: "Sierra Leone" },
  { value: "+65", label: "Singapore" },
  { value: "+677", label: "Solomon Islands" },
  { value: "+252", label: "Somali" },
  { value: "+34", label: "Spain" },
  { value: "+94", label: "Sri Lanka" },
  { value: "+1", label: "Saint Christopher and Nevis" },
  { value: "+1", label: "Saint Lucia" },
  { value: "+1", label: "Saint Vincent" },
  { value: "+249", label: "Sudan" },
  { value: "+597", label: "Suriname" },
  { value: "+268", label: "Swaziland" },
  { value: "+46", label: "Sweden" },
  { value: "+41", label: "Switzerland" },
  { value: "+963", label: "Syria" },
  { value: "+255", label: "Tanzania" },
  { value: "+66", label: "Thailand" },
  { value: "+228", label: "Togo" },
  { value: "+676", label: "Tonga" },
  { value: "+1", label: "Trinidad and Tobago" },
  { value: "+216", label: "Tunisia" },
  { value: "+90", label: "Turkey" },
  { value: "+256", label: "Uganda" },
  { value: "+971", label: "United Arab Emirates" },
  { value: "+44", label: "United Kingdom" },
  { value: "+1", label: "United States" },
  { value: "+598", label: "Uruguay" },
  { value: "+7", label: "Russia (Moscow)" },
  { value: "+58", label: "Venezuela" },
  { value: "+84", label: "Vietnam" },
  { value: "+1", label: "Virgin Islands (English)" },
  { value: "+1", label: "Virgin Islands (United States)" },
  { value: "+967", label: "Arab Republic of Yemen" },
  { value: "+969", label: "Yemen Democratic People's Republic" },
  { value: "+38", label: "Yugoslavia" },
  { value: "+260", label: "Zambia" },
  { value: "+263", label: "Zimbabwe" },
];

const initState = {
  single: {
    visible: false,
    value: "+61",
    label: "Australia",
    dataSource: SINGLE_DATA,
  },
};

const reducer = (state, action) => {
  const { type, key, visible, value, label, dataSource } = action;

  switch (type) {
    case "visible":
      return {
        ...state,
        [key]: {
          ...state[key],
          visible: !state[key].visible,
        },
      };

    case "value":
      return {
        ...state,
        [key]: {
          ...state[key],
          value,
        },
      };

    case "label":
      return {
        ...state,
        [key]: {
          ...state[key],
          label,
        },
      };

    case "dataSource":
      return {
        ...state,
        [key]: {
          ...state[key],
          dataSource,
        },
      };
  }
};

export default function UpdateProfile() {
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  let userData = useSelector((state) => state.user?.data);
  const history = useHistory();
  const dispatch = useDispatch();

  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newCountryCode, setNewCountryCode] = useState("+61");
  const [newCountry, setNewCountry] = useState("Australia");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");

  const [state, dispatchVisible] = useReducer(reducer, initState);
  const setVisible = (key) => {
    dispatchVisible({ type: "visible", key });
  };
  const setValue = (key, value) => {
    dispatchVisible({ type: "value", key, value });
  };
  const setLabel = (key, label) => {
    dispatchVisible({ type: "label", key, label });
  };
  const setDataSource = (key, value) => {
    dispatchVisible({ type: "dataSource", key, dataSource: value });
  };
  useEffect(() => {
    // 异步加载数据源测试
    setTimeout(() => {
      setValue("diy", ["1", "12"]);
    }, 0);
  }, []);

  const handleClick = async () => {
    var axios = require("axios");
    var data = JSON.stringify({
      UserName: newUsername ? newUsername : userData?.username,
      Email: newEmail ? newEmail : userData?.email,
      PhoneNumber: newPhoneNumber ? newPhoneNumber : userData?.phoneNumber,
      CountryCode: newCountry ? newCountry : userData?.countryCode,
      BankDetailCN: userData?.bankDetailCN,
      OverSeaBankDetail: userData?.overSeaBankDetail,
      AliPay: userData?.aliPay,
      Wechat: userData?.wechat,
      BlockChainWalletAddress: userData?.blockChainWalletAddress,
    });

    var config = {
      method: "put",
      url: BASE_URL + "/UserProfile",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        history.push("/user");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <FuncHeader
        title={"Change personal information"}
        goBack={() => history.push("/profile")}
      />
      <div style={{ marginTop: "60px" }}>
        <Cell title="Username">
          <Input
            clearable
            type="text"
            placeholder={userData?.username}
            value={newUsername}
            onChange={(value) => {
              setNewUsername(value);
            }}
          />
        </Cell>
        {/*<Cell title="Country">*/}
        {/* <Input*/}
        {/* clearable*/}
        {/* type="text"*/}
        {/* placeholder={userData?.countryCode}*/}
        {/* value={newCountryCode}*/}
        {/* onChange={(value) => {*/}
        {/* setNewCountryCode(value);*/}
        {/* }}*/}
        {/* />*/}
        {/*</Cell>*/}
        <Cell
          description={
            <Button size="xs" onClick={() => setVisible("single")}>
              Choose a country code
            </Button>
          }
          style={{ backgroundColor: "#fafafa", paddingLeft: "0" }}
        >
          {state.single.value + "" + state.single.label}
        </Cell>

        <Picker
          visible={state.single.visible}
          value={state.single.value}
          label={state.single.label}
          dataSource={state.single.dataSource}
          onOk={(selected) => {
            setNewCountryCode(selected[0]?.value);
            setNewCountry(selected[0]?.label);
            setValue(
              "single",
              selected.map((item) => item.value)
            );
            setLabel(
              "single",
              selected.map((item) => item.label)
            );
            setVisible("single");
          }}
          onCancel={() => setVisible("single")}
        />
        <Cell title="Mailbox">
          <Input
            clearable
            type="text"
            placeholder={userData?.email}
            value={newEmail}
            onChange={(value) => {
              setNewEmail(value);
            }}
          />
        </Cell>
        <Cell title="Mobile">
          <Input
            clearable
            type="text"
            placeholder={userData?.phoneNumber}
            value={newPhoneNumber}
            onChange={(value) => {
              setNewPhoneNumber(value);
            }}
          />
        </Cell>
        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => handleClick()}
          >
            submit
          </Button>
        </Cell>
      </div>
    </>
  );
}
