import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Badge, Cell } from "zarm";
import "zarm/dist/zarm.css";

import FuncHeader from "components/FuncHeader/FuncHeader";

export default function PaymentMethod() {
  const history = useHistory();

  return (
    <>
      <FuncHeader
        title={"Payment method"}
        goBack={() => history.push("/settings")}
      />
      <div style={{ marginTop: "60px" }}>
        <Cell hasArrow title="WeChat" onClick={() => history.push("/wechat")} />
        <Cell hasArrow title="Alipay" onClick={() => history.push("/alipay")} />
        <Cell
          hasArrow
          title="Overseas Bank"
          onClick={() => history.push("/overseaBank")}
        />
        <Cell
          hasArrow
          title="Bank of China"
          onClick={() => history.push("/cnbank")}
        />
        <Cell
          hasArrow
          title="Blockchain address ETH/USDT"
          onClick={() => history.push("/blockchainpayaddress")}
        />
      </div>
    </>
  );
}
