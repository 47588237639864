import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import { Input, Cell, Button } from "zarm";
import "zarm/dist/zarm.css";

import FuncHeader from "components/FuncHeader/FuncHeader";
import { Request } from "../../utils/request";
import { CONTENT_TYPE, REQUEST_METHOD } from "../../utils/request/config";

let id;
let areaType;

export default function BuyBlock() {
  let token = useSelector((state) => state.auth.token);
  let userId = useSelector((state) => state.auth.userId);
  const history = useHistory();
  const [payPassword, setPayPassword] = useState("");

  id = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    .id;
  areaType = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }).areaType;

  const handleClick = async () => {
    if (payPassword) {
      let request = new Request();
      try {
        let response = await request.fetchData("/Order", {
          method: REQUEST_METHOD.POST,
          contentType: CONTENT_TYPE.JSON,
          token,
          body: {
            AreaClaimId: id,
            PayPassword: payPassword,
          },
        });
        window.confirm("Successful purchase");
        history.push("/");
      } catch (error) {
        window.confirm("Error, please contact the administrator.");
      }
    } else {
      window.confirm("Please enter payment password");
    }
  };

  const shuoMing = (type) => {
    switch (type) {
      case "A":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: AUD ＄500 / RMB ￥2,500,
              <br />
              1, There are 5 rounds of income without sharing, and the total
              dividend CVA is about ＄1,620 / ￥8,100 (can be withdrawn)
              <br />
              2. Equipped with a 250,000 CVT delivery pass, released in 5
              rounds, can be redeemed for ＄2,500 / ￥12,500 products,
              <br />
              3. Or receive subsidy: 5 rounds total: ＄750 / ￥3,750 yuan,
              (withdrawable)
              <br />
              4. If N shares are shared, there will be: (5+N rounds) income.
              <br />
              5. Give 5 shares of ABG Golden Bean Red Shares
              <br />
            </p>
          </Cell>
        );
      case "B":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: AUD $ 1,000 / ￥ 5,000,
              <br />
              1, There are 5 rounds of income without sharing, and the total
              dividend CVA is about ＄3,240 / ￥16,200 (can be withdrawn)
              <br />
              2, Equipped with 500,000 CVT delivery tokens, released in 5
              rounds, can be redeemed for ＄5,000 /￥25,000 products,
              <br />
              3. Or receive subsidy: 5 rounds total: ＄1,500 /￥7,500
              (withdrawable)
              <br />
              4. If N shares are shared, there will be: (5+N rounds) income
              <br />
              5. Free 10 shares of ABG Golden Bean Red shares
              <br />
            </p>
          </Cell>
        );
      case "C":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: AUD ＄ 2,000 / ￥ 10,000
              <br />
              1, There are 5 rounds of income without sharing, and the total
              dividend CVA is about $6,480 / ￥32,400, (can be withdrawn)
              <br />
              2, Equipped with 1 million CVT delivery pass, released in 5
              rounds, can be redeemed for ＄10,000/￥50,000 products
              <br />
              3. Or receive subsidy: 5 rounds total: ＄3,000 /￥15,000,
              (withdrawable)
              <br />
              4. If N shares are shared, there will be: (5+N rounds) income
              <br />
              5. Free 20 shares of ABG Golden Bean Red shares
              <br />
            </p>
          </Cell>
        );
      case "D":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: AUD $ 4,000 / ￥ 20,000
              <br />
              1, There are 5 rounds of income without sharing, and the total
              dividend CVA is about ＄12,960 / ￥64,800 (can be withdrawn)
              <br />
              2. Equipped with 2 million CVT delivery tokens, released in 5
              rounds, can be redeemed for ＄20,000/￥100,000 products
              <br />
              3. Or receive subsidy: 5 rounds in total: ＄6,000 /￥30,000, (can
              be withdrawn)
              <br />
              4. If N shares are shared, there will be: (5+N rounds) income
              <br />
              5. Free 40 shares of ABG Golden Bean Red shares
              <br />
            </p>
          </Cell>
        );
      case "E":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: AUD $ 8,000 / ￥ 40,000
              <br />
              1, There are 5 rounds of income without sharing, and the total
              dividend CVA is about $25,920 /￥129,600 (withdrawable)
              <br />
              2, Equipped with 4,000,000 CVT delivery tokens, released in 5
              rounds, can be redeemed for $40,000 /￥200,000 products
              <br />
              3. Or receive the subsidy for 5 rounds total: ＄12,000 /￥60,000
              (withdrawable)
              <br />
              4. If N shares are shared, there will be: (5+N) rounds of profit
              <br />
              5. Free 80 shares of ABG Golden Bean Red shares, senior partner
              (limit)
              <br />
            </p>
          </Cell>
        );
      case "VIP":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: $20,000 / ￥100,000
              <br />
              1, There are 10 rounds of income without sharing, and the total
              dividend CVA is about ＄72,000 / ￥360,000 (can be withdrawn)
              <br />
              2, With 10,000,000 CVT delivery tokens, released in 10 rounds, you
              can redeem $100,000 / ￥500,000 products,
              <br />
              3. Or receive subsidy: 10 rounds total: ＄30,000 / ￥150,000 (can
              be withdrawn) ,<br />
              4. There are a total of 10 rounds of dividends in each of A, B, C,
              D, E, and VIP areas. The total cash CVA is about $172,440 /
              ￥862.200 yuan
              <br />
              + 25,500,000 CVT delivery certificates or receive subsidy: $76,500
              /￥382,500,
              <br />
              5. If any district shares N: There are (10+N rounds) income,
              <br />
              6, Free 200 shares of ABG Golden Bean Red shares, senior partner
              (limit), participate in the year-end bonus
              <br />
            </p>
          </Cell>
        );
      case "County Representative":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: $40,000 CVA /￥200,000
              <br />
              1, There are 20 rounds of income without sharing, and the total
              cash dividend CVA is about ＄288,000 / ￥1,440,000. (Withdrawable)
              <br />
              2, With 40,000,000 CVT delivery certificates, you can redeem
              $400,000 / ￥2,000,000 products
              <br />
              3. Or receive subsidy: 20 rounds total: ＄120,000 / ￥600,000
              yuan, (can be withdrawn)
              <br />
              4. There are 20 rounds of dividends in A, B, C, D, E, VIP, and F
              districts.
              <br />
              Cash CVA about $632,880 / ￥3,164,400 + 91,000,000 CVT delivery
              tokens
              <br />
              Or receive subsidy: $273,000 /￥1,365,000,
              <br />
              5. If any district shares N: There are (20+N rounds) income,
              <br />
              6, Free 400 shares of ABG Golden Bean Red shares, senior partner
              (limit), participate in the year-end bonus
              <br />
              7, Task: Share 35 in 12 months (40 in C area, 20 in D area, 10 in
              E area, VIP District 5) block point
              <br />
            </p>
          </Cell>
        );
      case "City Representative":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: $80,000 CVA /￥400,000
              <br />
              1, There are 40 rounds of income without sharing, a total of cash
              dividends CVA about $1,152,000 / ￥5,760,000 (Withdrawable)
              <br />
              2, with 160,000,000 CVT delivery tokens, Can redeem products
              valued at $16,000,000/￥8,000,000
              <br />
              3. Or receive subsidy: 40 rounds total: ＄480,000 / ￥2,400,000
              yuan, (withdrawable)
              <br />
              4. There are a total of 40 rounds of dividends in A, B, C, D, E,
              VIP, F, and G zones.
              <br />
              Cash CVA is about $2,417,760 / ￥12,088,800 + CVT delivery token
              342,000,000
              <br />
              Or receive subsidy: $720,000 /￥3,600,000
              <br />
              5. If N shares are shared in any district: there is (40+N rounds)
              income,
              <br />
              6, Free 800 shares of ABG Golden Bean Red shares, senior partner
              (limit), participate in the year-end bonus
              <br />
              7, Task: Share 155 in 18 months (C area 80, D area 40, E area 20,
              VIP area 10, F area 5) block points
              <br />
            </p>
          </Cell>
        );
      case "Provincial Representative":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: $160,000 CVA /￥800,000
              <br />
              1. There are 80 rounds of income without sharing, and the total
              cash dividend CVA is about $4,608,000 /￥23,040,000 (withdrawable)
              <br />
              2. Equipped with 640,000,000 CVT delivery tokens, Can redeem
              products valued at $6,400,000/￥32,000,000,
              <br />
              3. Or receive subsidy: 80 rounds total: $1,920,000 / ￥9,600,000
              yuan, (can be withdrawn)
              <br />
              4. There are 80 rounds of dividends in A, B, C, D, E, VIP, F, G,
              and H zones.
              <br />
              Cash CVA is about $9,443,520 / ￥47,217,600 + 1,324,000,000 CVT
              delivery tokens
              <br />
              Or receive subsidy: ＄3,360,000 / ￥16,800,000
              <br />
              5. If N shares are shared in any district: there is (80+N rounds)
              income,
              <br />
              6. Give 1,600 shares of ABG Golden Bean Red, and the senior
              partner (limit) will participate in the year-end dividend.
              <br />
              7. Task: Share 315 in 24 months (160 in C area, 80 in D area, 40
              in E area, 20 in VIP area,
              <br />
              District F: 10 county-level representative districts, District G:
              city representative 5) block points
              <br />
            </p>
          </Cell>
        );
      case "Country representative":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: $320,000 CVA / ￥1,600,000
              <br />
              1. There are 160 rounds of income without sharing, and a total of
              cash dividends CVA of about $15,360,000 /￥76,800,000 (can be
              withdrawn)
              <br />
              2. Equipped with 2,560,000,000 CVT delivery tokens, Can redeem
              products valued at $25,600,000/￥128,000,000,
              <br />
              3. Or receive subsidy: 160 rounds in total: ＄7,680,000 /
              ￥38,400,000 yuan, (can be withdrawn)
              <br />
              4. There are a total of 160 rounds of dividends in A, B, C, D, E,
              VIP, F, G, H, and I areas.
              <br />
              Cash CVA is about $34,247,040 / ￥171,235,200 + 5,208,000,000 CVT
              delivery tokens
              <br />
              Or receive subsidy: ＄14,400,000 /￥72,000,000
              <br />
              5. If N shares are shared in any district: there is (160+N rounds)
              income,
              <br />
              6. Give 3,200 shares of ABG Golden Bean Red, senior partner
              (limit) to participate in the year-end bonus.
              <br />
              7. Task: Share 635 in 30 months (320 in C area, 160 in D area, 80
              in E area, 40 in VIP area,
              <br />
              District F represents 20 at the county level, District G
              represents 10 at the municipal level, and District H represents 5
              at the provincial level) block points
              <br />
            </p>
          </Cell>
        );
      case "Continent representative (seven continents in the world)":
        return (
          <Cell title="Description">
            <p>
              One-time consumption: $640,000 CVA / ￥3,200,000
              <br />
              1. There are 320 rounds of income without sharing, and a total of
              cash dividends CVA of about $73,728,000 /￥368,640,000
              (withdrawable)
              <br />
              2. Equipped with 1,024,000,000 CVT delivery tokens, Can redeem
              products worth ￥102,400,000/￥512,000,000,
              <br />
              3. Or receive subsidies: 360 rounds in total: ＄34,560,000 /
              ￥172,800,000 yuan, (can be withdrawn)
              <br />
              4. A total of A, B, C, D, E, VIP, F, G, H, I, K districts each
              have 360 ​​rounds of dividends,
              <br />
              The total cash dividend CVA is about $142,222,080 / ￥711,110,400
              + 20,656,000,000 CVT delivery tokens
              <br />
              Or can receive consumption subsidy: $63,360,000 /￥316,800,000
              <br />
              5. If N shares are shared in any district: there is (320+N rounds)
              income,
              <br />
              6. Give 6,000 shares of ABG Golden Bean Red, and senior partner
              (limit) will participate in the year-end bonus.
              <br />
              7. Tasks: 1275 shares in 36 months (640 in C area, 320 in D area,
              160 in E area, 80 in VIP area,
              <br />
              There are 40 county-level representatives from F district, 20
              representatives from G district and city, and 10 provincial
              representatives from H district. (5 national representatives in
              Zone I) block points
              <br />
            </p>
          </Cell>
        );
    }
  };

  return (
    <>
      <FuncHeader title={"Purchase package"} goBack={() => history.push("/")} />
      <div style={{ marginTop: "60px" }}>
        <Cell title="Block type">
          <Input readOnly type="text" defaultValue={areaType + "Block"} />
        </Cell>
        <Cell title="Payment password">
          <Input
            type="password"
            value={payPassword}
            onChange={(value) => {
              setPayPassword(value);
            }}
            placeholder={"Please enter payment password"}
          />
        </Cell>
        {shuoMing(areaType)}
        <Cell>
          <Button
            block
            theme="primary"
            style={{ marginTop: "20px" }}
            onClick={() => handleClick()}
          >
            confirm purchase
          </Button>
        </Cell>
      </div>
    </>
  );
}
